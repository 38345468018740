import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faSmile,
  faTasks,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

export default function Bannercard() {
  return (
    <div className="bannercard">
      <h2 className="font-extrabold margin-2">
        Visa And Travel Package For All Emirates
      </h2>
      {/* <p>
        Depending on your purpose or plans, you may apply for 96 hours, 14 days,
        30 days (short term) or 90 days (long term) Dubai Visa (Single &
        Multiple entry). We, Kingsland Travels are one of the leading UAE Visa
        Experts offering visa services at the lowest prices and minimum
        documentation.
      </p> */}
      <div className="display-flex justify-space-around ">
        <div>
          <FontAwesomeIcon icon={faClock} />{" "}
          <p className="exp font-extrabold">20+</p>
          <p>Years of Experience</p>
        </div>
        <div>
          <FontAwesomeIcon icon={faSmile} />
          <p className="exp font-extrabold">12k</p> <p>Happy Customers</p>
        </div>
        <div>
          <FontAwesomeIcon icon={faTasks} />
          <p className="exp font-extrabold">15k</p>
          <p>Completed Work</p>
        </div>
        <div>
          <FontAwesomeIcon icon={faGlobe} />
          <p className="exp font-extrabold">25k</p> <p>Total Tours</p>
        </div>
      </div>
    </div>
  );
}
