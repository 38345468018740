import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/9.png";
export default function Navbar() {
  const [navbarVisible, setNavbarVisible] = useState(false);

  const [navLinksVisible, setNavLinksVisible] = useState(false);
  const toggleNavLinks = () => {
    setNavLinksVisible(!navLinksVisible);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setNavbarVisible(true);
      } else {
        setNavbarVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <header className={` ${navbarVisible ? "" : "navbar-hidden"}`}>
        <nav className="navbar">
          <div className="logo">
            <img src={logo} alt="KINGSLAND TRAVEL & TOURISM L.L.C" />
          </div>

          <ul className={`nav-links ${navLinksVisible ? "show" : ""}`}>
            <li>
              {/* Replace anchor tags with Link components */}
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>
        {navLinksVisible && (
          <ul className="nav-links-mobile">
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        )}
        <div class="hamburger" onClick={toggleNavLinks}>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </div>
      </header>
    </>
  );
}
