import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Thailand from "../assets/thailand.jpg";
import Turkey from "../assets/turkey.jpg";
import Singapore from "../assets/Singapore.jpg";
import Oman from "../assets/oman.jpg";
import China from "../assets/china.jpg";
import Malaysia from "../assets/malasia.jpg";
import Bahrain from "../assets/baharin.jpg";

export default function Packages() {
  const carouselOptions = {
    items: 3, // Show one item at a time
    responsive: {
      600: {
        items: 2, // Show two items on screens wider than 600px
      },
      1000: {
        items: 3, // Show three items on screens wider than 1000px
      },
      1200: {
        items: 5, // Show five items on screens wider than 1200px
      },
    },
  };
  const tourPackages = [
    {
      title: "Oman Visa",
      description: "Oman - e-visa available",
      price: "$1500",
      imageUrl: Oman,
    },
    {
      title: "China Visa",
      description: "China - e-visa available",
      price: "$2000",
      imageUrl: China,
    },
    {
      title: "Malaysia Visa",
      description: "Malaysia - e-visa available",
      price: "$1800",
      imageUrl: Malaysia,
    },
    {
      title: "Turkey Visa",
      description: "Turkey - e-visa available",
      price: "$1700",
      imageUrl: Turkey,
    },
    {
      title: "Bahrain Visa",
      description: "Bahrain - e-visa available",
      price: "$1600",
      imageUrl: Bahrain,
    },
    {
      title: "Thailand Visa",
      description: "Thailand - e-visa available",
      price: "$1900",
      imageUrl: Thailand,
    },
    {
      title: "Singapore Visa",
      description: "Singapore - e-visa available",
      price: "$1800",
      imageUrl: Singapore,
    },
  ];

  return (
    <div className="carousel-container">
      <OwlCarousel options={carouselOptions}>
        {tourPackages.map((pkg, index) => (
          <div className="package-card" key={index}>
            <img src={pkg.imageUrl} alt={pkg.title} />
            <h3>{pkg.title}</h3>
            <p>{pkg.description}</p>
            {/* <p>Price: {pkg.price}</p> */}
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
}
