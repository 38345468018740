import React from "react";
import Visa from "../assets/visa.jpg";
import Pakages from "../assets/2.jpg";
import Group from "../assets/3.jpg";
import Honeymoon from "../assets/4.jpg";
import Bookings from "../assets/6.jpg";
import Plane from "../assets/plane.jpg";

export default function Cards() {
  const whatsappNumber = "971582825292";

  return (
    <div>
      <section className="cards">
        {/* Visa Assistance Card */}
        <div className="card animated-card">
          <img src={Visa} alt="Visa Assistance" />
          <h3 className="blue">Visa Assistance</h3>
          <p className="font-regular">
            Expert guidance for smooth visa applications in UAE, including work
            visa, visit visa, business visa, and study or leisure trips to Dubai
            and other destinations.
          </p>
          <button>
            <a
              href={`https://wa.me/${whatsappNumber}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              know more
            </a>
          </button>
        </div>

        {/* Tour Packages Card */}
        <div className="card animated-card">
          <img src={Pakages} alt="Tour Packages" />
          <h3 className="blue">Tour Packages</h3>
          <p className="font-regular">
            Discover the UAE's wonders with our curated tour packages,
            showcasing cultural, historical, and adventurous experiences for all
            travelers.
          </p>
          <button>
            <a
              href={`https://wa.me/${whatsappNumber}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              know more
            </a>
          </button>
        </div>

        {/* Group Tours Card */}
        <div className="card animated-card">
          <img src={Group} alt="Group Tours" />
          <h3 className="blue">Group Tours</h3>
          <p className="font-regular">
            Create unforgettable memories with our group tours, fostering
            camaraderie and exploration in the UAE's diverse landscapes.
          </p>
          <button>
            <a
              href={`https://wa.me/${whatsappNumber}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              know more
            </a>
          </button>
        </div>

        {/* Honeymoon Packages Card */}
        <div className="card animated-card">
          <img src={Honeymoon} alt="Honeymoon Packages" />
          <h3 className="blue">Honeymoon Packages</h3>
          <p className="font-regular">
            Embark on an enchanting honeymoon getaway to romantic destinations
            across the UAE, tailored to your dream vacation.
          </p>
          <button>
            <a
              href={`https://wa.me/${whatsappNumber}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              know more
            </a>
          </button>
        </div>

        {/* Hotel Bookings Card */}
        <div className="card animated-card">
          <img src={Bookings} alt="Hotel Bookings" />
          <h3 className="blue">Hotel Bookings</h3>
          <p className="font-regular">
            Plan your stay with our hotel bookings, offering the perfect
            accommodations to suit your preferences and budget in UAE.
          </p>
          <button>
            <a
              href={`https://wa.me/${whatsappNumber}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              know more
            </a>
          </button>
        </div>

        {/* Flight Bookings Card */}
        <div className="card animated-card">
          <img src={Plane} alt="Flight Bookings" />
          <h3 className="blue">Flight Bookings</h3>
          <p className="font-regular">
            Plan your travel with our flight bookings, ensuring convenient and
            affordable flights to Dubai that suit your preferences and budget.
          </p>
          <button>
            <a
              href={`https://wa.me/${whatsappNumber}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              know more
            </a>
          </button>
        </div>
      </section>
    </div>
  );
}
