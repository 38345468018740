import React from "react";
import Nav from "../components/navigationbar";
import Cont from "../assets/wold.jpg";
import Footer from "../components/footor";
import logo from "../assets/9.png";
import { Helmet } from "react-helmet";
export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact Us | Kingsland Travel & Tourism</title>
        <meta
          name="description"
          content="Get your UAE tourist visa with attractive offers. Kingsland Travel & Tourism provides the best travel services."
        />

        <link rel="icon" href={logo} type="image/x-icon" />

        <link rel="canonical" href="https://thekingslandtravels.com/contact" />
      </Helmet>
      <div className="con">
        <Nav />
        <div>
          <div className="contact-container">
            <div className="contact-form">
              <form name="contact6" method="post" className="form-containerx">
                <input type="hidden" name="form-name" value="contact6" />

                {/* <form name="contact" method="POST" data-netlify="true"> */}
                {/* <input type="hidden" name="form-name" value="contact" /> */}
                <h2 className="text-center">Contact Us</h2>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  placeholder="Your Name"
                />
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  placeholder="Your Email"
                />
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  required
                  placeholder="Your Phone Number"
                />
                {/* <textarea
                  id="message"
                  name="message"
                  rows="4"
                  required
                  placeholder="Your Message"
                /> */}
                <div className="text-center">
                  {" "}
                  <button type="submit">Request call back</button>
                </div>
              </form>
            </div>

            <div className="contact-address">
              <img src={Cont} al="kingsland travels" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
