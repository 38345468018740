import React from "react";
import About from "../assets/visa.jpg";
export default function AboutCard() {
  return (
    <>
      {" "}
      <h2 className="font-extrabold text-center">
        {" "}
        Welcome to{" "}
        <span className="blue">Kingsland Travel & Tourism L.L.C</span>{" "}
      </h2>
      <div className="about-section">
        <div className="left-side">
          {/* Replace "img-url" with the URL of the image you want to display */}
          <img src={About} alt="About" />
        </div>
        <div className="right-side">
          {/* <p className="font-extrabold">
            Welcome to Kingsland Travel & Tourism L.L.C - Your Gateway to UAE
            Visa and Unforgettable Tours! At Kingsland Travel & Tourism, we take
            immense pride in being a premier travel and tourism company that
            specializes in providing hassle-free UAE visa services and creating
            unforgettable tour experiences. With a wealth of experience and a
            passion for travel, we are your trusted partner in exploring the
            wonders of the United Arab Emirates.
          </p> */}

          <div className="nemorpisam">
            <h3 className="font-extrabold blue"> Best Tours In Dubai</h3>
            <p>
              A strict screening process ensures that we only offer the best
              tours and trip packages.
            </p>
          </div>
          <div className="nemorpisam">
            <h3 className="font-extrabold blue"> Best Price In Dubai </h3>
            <p>
              We guarantee you the best price. Found a lower price? We will
              match it.
            </p>
          </div>
          <div className="nemorpisam">
            <h3 className="font-extrabold blue"> Free Cancellation</h3>
            <p>
              We understand how important flexibility is right now, just one
              click and it's done!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
