import React, { useState, useEffect } from "react";
import logo from "../assets/9.png";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [navbarVisible, setNavbarVisible] = useState(true);
  const [navLinksVisible, setNavLinksVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setNavbarVisible(true);
      } else {
        setNavbarVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleHomeLinkClick = () => {
    // Reload the page when clicking on the "Home" link
    window.location.reload();
  };

  const toggleNavLinks = () => {
    setNavLinksVisible(!navLinksVisible);
  };

  return (
    <>
      <header className=" postion-realative header2">
        <nav className="navbar">
          <div className="logo">
            <img src={logo} alt="KINGSLAND TRAVEL & TOURISM L.L.C" />
          </div>
          <ul className="nav-links">
            <li>
              {/* Replace anchor tags with Link components */}
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>
        {navLinksVisible && (
          <ul className="nav-links-mobile">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        )}
        <div class="hamburger" onClick={toggleNavLinks}>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </div>
      </header>
    </>
  );
}
