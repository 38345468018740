import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"; // Import the specific icon packages

import Logo from "../assets/9.png";

export default function Footer() {
  return (
    <>
      <footer className="footer-container">
        <div className="footer-logo">
          <img src={Logo} alt="Company Logo" />
        </div>
        <div className="footer-links">
          <h3>Links</h3>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/services">Services</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>{" "}
        <div>
          <h3>soicial Links</h3>
          <div className="footer-social">
            <a href="https://www.facebook.com/Kingslandholidays/">
              <FontAwesomeIcon icon={faFacebook} />{" "}
              {/* Use the imported icon component */}
            </a>
            {/* <a href="#">
              <FontAwesomeIcon icon={faTwitter} />{" "}
            </a> */}
            <a href="https://www.instagram.com/kingsland_tourism">
              <FontAwesomeIcon icon={faInstagram} />{" "}
              {/* Use the imported icon component */}
            </a>
          </div>{" "}
        </div>
        <div
          className="address
        "
        >
          <h3>Kingsland Travel & Tourism L.L.C</h3>
          <p>
            <br />
            United Arab Emirates <br /> <br />
            0507923112 <br />
            <br /> <br />
            Kerala India <br /> <br />
            043301140 <br />
            <br />
          </p>
        </div>
      </footer>
      <div className="text-center copy">
        {" "}
        &copy; 2023 Kingsland Travel & Tourism L.L.C. Designed by{" "}
        <a href="http://billingkopos.com/">billingko</a>
      </div>
    </>
  );
}
