import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import About from "./about/about";
import Services from "./serv/services";
import Contact from "./contact/contact";

class Navigator extends React.Component {
  render() {
    return (
      <>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </>
    );
  }
}

function Home() {
  return (
    <div style={{ paddingTop: "20%", textAlign: "center" }}>
      <h1>Home</h1>
    </div>
  );
}
export default Navigator;
